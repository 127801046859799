import React from "react";
import urijs from "urijs";
import "./Open3dDatasetSection.scss";
function validateDistribution(disData) {
    const { format, downloadURL, accessURL } = disData;
    if (!format || typeof format !== "string") {
        return false;
    }
    if (format.toLocaleLowerCase().trim() !== "esri sceneserver") {
        return false;
    }
    const url = downloadURL ? downloadURL : accessURL;
    if (!url || typeof url !== "string") {
        return false;
    }
    if (!url.match(/\W*SceneServer\W*/)) {
        return false;
    }
    return true;
}
function createDTLink(dataset, disData, configData) {
    var _a, _b;
    const { landingPage } = dataset;
    const { extraConfigData } = configData;
    if (!((_a = extraConfigData === null || extraConfigData === void 0 ? void 0 : extraConfigData["3dDatasetDigitalTwinInstances"]) === null || _a === void 0 ? void 0 : _a.length)) {
        console.log("Open 3D Dataset Button: cannot locate Digital Twin Config.");
        return null;
    }
    if (!landingPage || typeof landingPage !== "string") {
        console.log("Open 3D Dataset Button: dataset landing page is empty.");
        return null;
    }
    const uri = urijs(landingPage);
    const sourceDomain = uri.host();
    const itemId = (_b = uri.search(true)) === null || _b === void 0 ? void 0 : _b.id;
    if (!itemId) {
        console.log("Open 3D Dataset Button: dataset landing page doesn't contains 3D item id.");
        return null;
    }
    const dtConfigData = extraConfigData["3dDatasetDigitalTwinInstances"];
    const dtConfig = dtConfigData.find((item) => {
        if (!item.sourceDataDomain || item.sourceDataDomain === "*") {
            return true;
        }
        if (typeof item.sourceDataDomain === "string" &&
            sourceDomain.indexOf(item.sourceDataDomain) !== -1) {
            return true;
        }
        return false;
    });
    if (!dtConfig || !(dtConfig === null || dtConfig === void 0 ? void 0 : dtConfig.url)) {
        console.log("Open 3D Dataset Button: cannot find a matched DT config.");
        return null;
    }
    return urijs(dtConfig.url).fragment(`magdaLayers=${itemId}`).toString();
}
const Open3dDatasetSection = (props) => {
    var _a;
    const dataset = props.dataset;
    const distributionId = props.distributionId;
    const configData = props.config;
    if (!((_a = dataset === null || dataset === void 0 ? void 0 : dataset.distributions) === null || _a === void 0 ? void 0 : _a.length)) {
        // no distribution available
        console.log("Open 3D Dataset Button: no distribution available.");
        return null;
    }
    let selectedDis;
    if (distributionId) {
        selectedDis = dataset.distributions.find((item) => (item === null || item === void 0 ? void 0 : item.identifier) === distributionId);
        if (!selectedDis) {
            console.log("Open 3D Dataset Button: Cannot locate distribution with id: " +
                distributionId);
            return null;
        }
        if (!validateDistribution(selectedDis)) {
            console.log("Open 3D Dataset Button: the distribution doesn't contain a ESRI SceneServer API access url.");
            return null;
        }
    }
    else {
        selectedDis = dataset.distributions.find((item) => validateDistribution(item));
        if (!selectedDis) {
            console.log("Open 3D Dataset Button: the dataset has no supported 3D data distribution.");
            return null;
        }
    }
    const dtLink = createDTLink(dataset, selectedDis, configData);
    if (!dtLink) {
        return null;
    }
    return (React.createElement("div", { className: "magda-ui-plugin-component-open-3d-dataset no-print" },
        React.createElement("h3", { className: "section-heading" }, "Map Preview"),
        React.createElement("p", null, "Map Preview cannot preview 3D datasets. Please click the button to view the 3D datasets in Digital Twin."),
        React.createElement("a", { className: "au-btn au-btn--secondary open-3d-dataset-in-dt-button", "aria-label": "Open 3D Dataset in Digital Twin", target: "_blank", href: dtLink },
            React.createElement("span", null, "Open 3D Dataset in Digital Twin"))));
};
export default Open3dDatasetSection;
